//import { default as Agreement } from "../core/Agreement";
//import { default as App } from "../core/App";
//import { default as Avatar } from '../core/Avatar';
import { default as User } from "../core/User";
//import { default as ReleaseNotes } from "../core/ReleaseNotes";
//import { default as Landing } from "../core/Landing";

// Reducers
import { STATE_KEY as AGREEMENT_STATE_KEY, reducer as agreementReducer } from "./Agreement";
import { STATE_KEY as AVATAR_STATE_KEY, reducer as avatarReducer } from "./Avatar";
import { STATE_KEY as CLINICAL_STATE_KEY, reducer as clinicalReducer } from "./Clinical";
import { STATE_KEY as MOLECULAR_STATE_KEY, reducer as molecularReducer } from "./Molecular";
import { STATE_KEY as DASHBOARD_STATE_KEY, reducer as dashboardReducer } from "./Dashboard";
import { STATE_KEY as SUMMARY_BOXES_STATE_KEY, reducer as summaryBoxReducer } from "./SummaryBoxes";
import { STATE_KEY as USER_STATE_KEY, reducer as userReducer } from "./User";
import { STATE_KEY as USER_MANUALS_STATE_KEY, reducer as userManualsReducer } from "./UserManuals";
import { STATE_KEY as RELEASE_NOTES_STATE_KEY, reducer as releaseNotesReducer } from "./ReleaseNotes";
import { STATE_KEY as AVATAR_ID_IMPORT_STATE_KEY, reducer as avatarIDImportReducer } from "./AvatarIDImport";

export const coreReducers = {
  [USER_STATE_KEY]: userReducer,
  [SUMMARY_BOXES_STATE_KEY]: summaryBoxReducer,
  [DASHBOARD_STATE_KEY]: dashboardReducer,
  [MOLECULAR_STATE_KEY]: molecularReducer,
  [CLINICAL_STATE_KEY]: clinicalReducer,
  [AVATAR_STATE_KEY]: avatarReducer,
  [RELEASE_NOTES_STATE_KEY]: releaseNotesReducer,
  [USER_MANUALS_STATE_KEY]: userManualsReducer,
  [AGREEMENT_STATE_KEY]: agreementReducer,
  [AVATAR_ID_IMPORT_STATE_KEY]: avatarIDImportReducer
};
