import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import configureReducers from "./configureReducers";

export default function configureStore(reducerRegistry) {
    let store = {};
    const rootReducer = configureReducers(reducerRegistry.getReducers());

    if (process.env.NODE_ENV === "production") {
        const middlewares = [thunk];
        const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
        store = createStoreWithMiddleware(rootReducer);
    } else {
        const middlewares = [thunk, createLogger()];

        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
        store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

        if (module.hot) {
            // Enable Webpack hot module replacement for reducers
            module.hot.accept("../core", () => {
                const nextReducer = require("../core").coreReducers; // eslint-disable-line global-require
                reducerRegistry.register(nextReducer);
            });
        }
    }

    reducerRegistry.setChangeListener(reducers => {
        store.replaceReducer(configureReducers(reducers));
    });

    return store;
}
