/**
 * This the common API client for the Orien Avatar Platform
 */
import axios from "axios";
//import { default as User } from "../User";

export const GENERIC_OA_API_KEY = "z5zmYVyKHB9RSa8kLTkPt1NTydF6qFW14CeM3dyg";

/**
 * Base Orien Avatar API Class
 */
const baseURL = "";
export default class OrienAvatarAPI {
    /**
     * Perform Ajax Request to the API server
     * @param {*} options
     */
    static request(options, dispatch) {
        return new Promise((resolve, reject) => {
            options.baseURL = baseURL; //Get Base URL
            axios
                .request(options)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    // Not actually sure we should even be killing the session here, hmmmmm....
                    /*
                     *if (
                     *    dispatch &&
                     *    error.response &&
                     *    error.response.status &&
                     *    error.response.status == 401
                     *) {
                     *    dispatch(User.actions.killSession());
                     *}
                     */
                    reject(error);
                });
        });
    }
}
