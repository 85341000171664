// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/m2gen-icons.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/m2gen-icons.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/m2gen-icons.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/m2gen-icons.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#m2gen-icons" });
// Module
exports.push([module.id, "@font-face{font-family:\"m2gen-icons\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-weight:normal;font-style:normal}[data-icon]:before{font-family:\"m2gen-icons\" !important;content:attr(data-icon);font-style:normal !important;font-weight:normal !important;font-variant:normal !important;text-transform:none !important;speak:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}[class^=\"fontastic-icon-\"]:before,[class*=\" fontastic-icon-\"]:before{font-family:\"m2gen-icons\" !important;font-style:normal !important;font-weight:normal !important;font-variant:normal !important;text-transform:none !important;speak:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.fontastic-icon-sign-in:before{content:\"\\6c\"}.fontastic-icon-molecule:before{content:\"\\6d\"}.fontastic-icon-expand:before{content:\"\\78\"}.fontastic-icon-compress:before{content:\"\\63\"}.fontastic-icon-times-circle-o:before{content:\"\\66\"}.fontastic-icon-star-o:before{content:\"\\73\"}.fontastic-icon-flask:before{content:\"\\74\"}.fontastic-icon-clear-filters:before{content:\"\\61\"}\n", ""]);
// Exports
module.exports = exports;
